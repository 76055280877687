// Auditorium.js
import React from "react";
import "../Styles/Auditorium.css"; // Assuming you will style it separately
import auditoriumImage from "../assets/images/auditorium.jpg"; // Corrected image import

const Auditorium = () => {
  return (
    <div className="auditorium">
      <img src={auditoriumImage} alt="Auditorium-Image" /> {/* Using imported image */}
      <h1>Auditorium Meeting</h1>
      <p>
        The visit also presented a delightful occasion for celebration, as we
        shared moments of camaraderie with our colleagues against the backdrop
        of this esteemed institution.
      </p>
      <p>
        From exploring the iconic architecture to participating in interactive
        sessions, the day was a blend of learning and team bonding. As we
        exchanged ideas and experiences in the inspiring surroundings of Mumbai
        University, the visit became a memorable chapter in our collective
        professional journey, fostering a deeper sense of connection and
        appreciation among our team.
      </p>
    </div>
  );
};

export default Auditorium;
