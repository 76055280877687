// AuditoriumPage.js
import React from "react";
import Header from "../components/Header"; // Import Header component
import Auditorium from "../components/Auditorium"; // Import Auditorium component
import Footer from "../components/Footer"; // Import Footer component

const AuditoriumPage = () => {
  return (
    <div>
      <Header /> {/* Render the Header */}
      <Auditorium /> {/* Render the Auditorium content */}
      <Footer /> {/* Render the Footer */}
    </div>
  );
};

export default AuditoriumPage;
