import React from 'react';
import '../Styles/CertificatesPage.css';
import Header from '../components/Header';
import ProvisionalDegreeCertificate from '../components/ProvisionalDegreeCertificate';
import Footer from '../components/Footer';


const CertificatesPage = () => {
    return (
        <div className="certificates-page">
            <Header />
            <ProvisionalDegreeCertificate />
            <Footer />
        </div>
    );
};

export default CertificatesPage;
