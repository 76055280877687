// Import necessary libraries
import React, { useState, useEffect } from 'react';
import '../Styles/Slider.css'; // Make sure this path is correct

// Import images directly
import taj from '../assets/images/taj.jpg';
import topRounds from '../assets/images/top_rounds_CZ_2023.png';

// Image data array
const images = [
    { id: 1, src: taj, alt: 'Mission Image 1' },
    { id: 2, src: taj, alt: 'Mission Image 2' },
    { id: 3, src: topRounds, alt: 'Mission Image 3' },
    { id: 4, src: topRounds, alt: 'Mission Image 4' },
];

const ImageSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    // Automatic slider transition
    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(slideInterval); // Clear interval on component unmount
    }, []);

    return (
        <div className="slider-container">
            <div
                className="slider"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            >
                {images.map((image) => (
                    <div className="slide" key={image.id}>
                        <img src={image.src} alt={image.alt} />
                    </div>
                ))}
            </div>
            <div className="overlay">
                <div className="mission-text">
                    <h1 className="/* The `mission-heading` class is used to style the heading element
                    `<h1>` within the component. It is likely defining specific
                    styles such as font size, color, alignment, or any other visual
                    properties to make the heading stand out and convey the message
                    effectively. */
                    mission-heading">OUR MISSION</h1>
                    <p className="mission-statement">
                        We empower <span className="highlight">universities</span> to embrace the changing world by <span className="highlight">digitalizing student life</span> on campus.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;
