// src/pages/DiwaliPage.js
import React from 'react';
import Header from '../components/Header';
import DiwaliCelebration from '../components/DiwaliCelebration'; 
import Footer from '../components/Footer';



const DiwaliPage = () => {
  return (
    <div>
      <Header />
      <DiwaliCelebration />
      <Footer />
    </div>
  );
};

export default DiwaliPage;
