// src/components/IndependenceDayCelebration.js
import React from 'react';
import '../Styles/IndependenceDayCelebration.css'; // Make sure to create this CSS file
import independenceDayImage from '../assets/images/independentday.jpg'; // Adjust the path to where your image is located

const IndependenceDayCelebration = () => {
  return (
    <div className="content">
      <img src={independenceDayImage} alt="Independence-Day-Celebration" />
      <h1>Azadi Ka Amrit Mahotsav</h1>
      <p>The Azadi Ka Amrit Mahotsav means elixir of energy of independence.</p>
      <p>
        Celebrating India’s Independence Day in the office was a patriotic and festive occasion that filled the workplace with a sense of pride and unity. The office space echoed with the vibrant hues of the national flag, and patriotic decorations adorned desks and common areas. The day commenced with the singing of the national anthem, instilling a profound sense of reverence and love for the nation.
      </p>
    </div>
  );
};

export default IndependenceDayCelebration;
