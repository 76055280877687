// src/components/WhyChooseUs.js

import React from 'react';
import '../Styles/WhyChooseUs.css';

const WhyChooseUs = () => {
    return (
        <section className="why-choose-us-section">
            <div className="why-choose-us-title">
                <h1>Why Choose Us</h1>
                <p className="why-choose-us-subtitle">Check out some interesting facts about us</p>
            </div>

            <div className="why-choose-us-container">
                <div className="why-choose-us-card">
                    <div className="why-choose-us-card-icon">👔</div>
                    <h3>Strong Leaders</h3>
                    <p>We believe in nurturing leadership, ensuring every student gets the right guidance to shape their career and life.</p>
                </div>
                <div className="why-choose-us-card">
                    <div className="why-choose-us-card-icon">🎓</div>
                    <h3>Global Education</h3>
                    <p>Our programs provide education in culturally rich environments, promoting learning beyond textbooks.</p>
                </div>
                <div className="why-choose-us-card">
                    <div className="why-choose-us-card-icon">🔄</div>
                    <h3>Flexibility</h3>
                    <p>We offer flexible programs tailored to meet the needs of students, ensuring personal and professional growth.</p>
                </div>
                <div className="why-choose-us-card">
                    <div className="why-choose-us-card-icon">⚙️</div>
                    <h3>Integrity</h3>
                    <p>Our programs emphasize ethical learning and the importance of understanding different cultures with respect.</p>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseUs;
