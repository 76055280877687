import React from "react";
import "../Styles/Support.css"; // Import CSS file

const supportData = [
  { number: "1", title: "Onboarding", description: "Our dedicated team provides comprehensive onboarding support, ensuring a smooth transition to our platform." },
  { number: "2", title: "Training", description: "We offer training resources, including online tutorials, documentation, and live webinars, to empower you to master our system." },
  { number: "3", title: "Ongoing Support", description: "Our customer support team is available 24/7 to assist you with any technical issues or questions you may have." },
];

const Support = () => {
  return (
    <div className="support-container">
      <h2 className="support-title">Implementation and Support</h2>
      <div className="support-list">
        {supportData.map((item, index) => (
          <div key={index} className="support-item">
            <div className="support-number">{item.number}</div>
            <div className="support-content">
              <h3 className="support-heading">{item.title}</h3>
              <p className="support-description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Support;
