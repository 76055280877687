import React from "react";
import screenshotImage from "../assets/images/screenshot.png"; // Import the image
import "../Styles/Screenshot.css"; // Import the CSS file

const Screenshot = () => {
  return (
    <div className="screenshot-container">
      <h2 className="screenshot-title">
        See OSM in Action: Explore Our Portal with Live Screenshots
      </h2>
      <div className="screenshot-box">
        <img src={screenshotImage} alt="Screenshot" className="screenshot-image" />
      </div>
    </div>
  );
};

export default Screenshot;
