import React from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation
import '../Styles/Footer.css'; // Ensure this path is correct
import newFooterLogo from '../assets/images/edulablogo2footer.png';
import linkedinIcon from '../assets/images/linkedin.jpg';
import facebookIcon from '../assets/images/facebook.jpg';
import instagramIcon from '../assets/images/insta.jpg';
import youtubeIcon from '../assets/images/youtube.jpg';
import twitterIcon from '../assets/images/twitter.jpg';
import cycleIcon from '../assets/images/cycle_only_transparent.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={newFooterLogo} alt="Edulab Logo" />
          <div className="footer-address">
            <p>Address:<br />
              13, Kondivita Rd, near Pidilite Industries,<br />
              Marol MIDC Industry Estate, Andheri East,<br />
              Mumbai, Maharashtra 400093
            </p><br />
            <p>Email:<br />info@edulab.in</p>
          </div>
          <button className="demo-button">Request a Demo →</button>
        </div>

        <div className="footer-links">
          <div className="footer-column">
            <h3>Products</h3>
            <ul>
              <li><Link to="/aboutus">SFC solution</Link></li> {/* Use Link for internal routes */}
              <li><Link to="/aboutus">Admission</Link></li>
              <li><Link to="/aboutus">Exam LMS</Link></li>
              <li><Link to="/aboutus">Marksheet</Link></li>
            </ul>
          </div>

          <div className="footer-column">
            <h3>Company</h3>
            <ul>
              <li><Link to="/aboutpage">About Us</Link></li> {/* Link to internal About Us page */}
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/news">News</Link></li>
              <li><Link to="/career">Career</Link></li>
            </ul>
          </div>

          <div className="footer-column">
            <h3>Resources</h3>
            <ul>
              <li><a href="https://google.com">Blog</a></li>
              <li><a href="https://google.com">Tutorials</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h3>Legal</h3>
            <ul>
              <li><a href="https://google.com">Terms & Conditions</a></li>
              <li><a href="https://google.com">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="social-icons">
          <a href="https://www.linkedin.com/company/edulab/" target="_blank" rel="noreferrer">
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
          <a href="https://www.facebook.com/edulab.in/" target="_blank" rel="noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/edulab.in/" target="_blank" rel="noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://www.youtube.com/user/EdulabIndernship" target="_blank" rel="noreferrer">
            <img src={youtubeIcon} alt="YouTube" />
          </a>
          <a href="https://x.com/edulab_india?lang=en" target="_blank" rel="noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
        </div>
      </div>

      <div className="footer-decoration"></div>

      <div className="footer-image">
        <img src={cycleIcon} alt="Bicycle" />
      </div>
    </footer>
  );
};

export default Footer;
