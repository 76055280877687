import React from 'react';
import '../Styles/VerificationInfo.css';
import VerificationImage from '../assets/images/4adm.jpg'; 

const VerificationInfo = () => {
    return (
        <div className="verificationInfo-container">
            <img className="verificationInfo-image" src={VerificationImage} alt="Verification-Section-Image" />
            <h1 className="verificationInfo-title">Understanding Provisional Eligibility in Admissions</h1>
            <div className="verificationInfo-box">
                <div className="verificationInfo-item">
                    <strong>Temporary Admission Status:</strong>
                    Provisional eligibility allows students temporary admission, even if they haven’t met all standard requirements.
                </div>
                <div className="verificationInfo-item">
                    <strong>Conditional Acceptance:</strong>
                    Students may be provisionally eligible with conditions, such as achieving specific grades or submitting required documents within a set timeframe.
                </div>
                <div className="verificationInfo-item">
                    <strong>Probationary Period:</strong>
                    Provisional eligibility often involves a monitored period, after which students may transition to full eligibility based on performance.
                </div>
                <div className="verificationInfo-item">
                    <strong>Flexibility for Special Cases:</strong>
                    Provisional eligibility accommodates special cases, acknowledging unique circumstances like transfers or individual challenges.
                </div>
            </div>
        </div>
    );
}

export default VerificationInfo;
