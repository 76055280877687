import React from 'react';
import '../Styles/Aboutedu.css';

const Aboutedu = () => {
    return (
        <div className="aboutedu-container">
        <h1 className="aboutedu-header">About Edulab</h1>
        <div className="aboutedu-overview">
            <h2 className="aboutedu-title">Edulab Overview</h2>
            <p className="aboutedu-text">
                Edulab is a pioneering platform driving technological advancement across universities in Maharashtra, 
                empowering institutions to thrive in an increasingly digital and knowledge-driven economy.
            </p>
            <p className="aboutedu-text">
                With a proven track record of transforming education for over 50,000 students through strategic growth 
                and partnerships, Edulab is redefining educational access and operational efficiency for higher 
                education institutions across the state.
            </p>
            <p className="aboutedu-text">
                Our mission is to bridge the digital divide by fostering an inclusive transformation where technology 
                enhances educational equity, learning experiences, and future readiness. We go beyond digital adoption 
                to create a fully integrated, technology-centric academic environment that empowers both students and 
                institutions to excel in the 21st-century knowledge society.
            </p>
            <p className="aboutedu-text">
                We ensure cultural integration through our programs like INTERNATIONAL INTERNSHIP, STUDY ABROAD, GAP YEAR, 
                and INTERNATIONAL VOLUNTEERING. All our programs are designed to provide students with opportunities to stay 
                and interact with people from multiple countries, be supported by our local representatives in each destination, 
                and ultimately gain a deep understanding of local cultures and traditions—helping them become globally literate.
            </p>
        </div>
    </div>
    
    );
}

export default Aboutedu;
