// src/components/OfficePicnic.js
import React from 'react';
import '../Styles/OfficePicnic.css'; // Make sure to create the CSS file
import officePicnicImage from '../assets/images/travel.jpg'; // Adjust the path to where your image is located

const OfficePicnic = () => {
  return (
    <div className="content">
      <img src={officePicnicImage} alt="Office Picnic" />
      <h1>Office Picnic</h1>
      <p>
        The visit also presented a delightful occasion for celebration, as we shared moments of camaraderie with our colleagues against the backdrop of this esteemed institution.
      </p>
      <p>
        From exploring the iconic architecture to participating in interactive sessions, the day was a blend of learning and team bonding. As we exchanged ideas and experiences in the inspiring surroundings of Mumbai University, the visit became a memorable chapter in our collective professional journey, fostering a deeper sense of connection and appreciation among our team.
      </p>
    </div>
  );
};

export default OfficePicnic;
