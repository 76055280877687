import React from "react";
import "../Styles/SolutionSection.css"; // Import the CSS file

const SolutionSection = () => {
  const solutions = [
    {
      title: "Modern Marking",
      description:
        "Our on-screen marking system allows teachers to mark student work directly on tablets or computers. It provides a dynamic and engaging evaluation environment.",
    },
    {
      title: "Real-Time Feedback",
      description:
        "Provide immediate feedback to students, promoting their understanding and learning. Our system supports real-time annotations and personalized messages.",
    },
    {
      title: "Data-Driven Insights",
      description:
        "Gain valuable insights into student performance through detailed analytics and reports. Monitor progress, identify areas for improvement, and make informed decisions.",
    },
  ];

  return (
    <div className="solution-container">
      <div className="overlay"></div>
      <h2 className="solution-title">About Our Solution</h2>
      <div className="solution-grid">
        {solutions.map((item, index) => (
          <div key={index} className="solution-card">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SolutionSection;
