import React from 'react';
import '../Styles/MigrationInfo.css';
import MigrationImage from '../assets/images/migration12.jpg'; // adjust the path if needed

const MigrationInfo = () => {
    return (
        <div className="content-section">
            <img className="migration" src={MigrationImage} alt="Significance-of-Migration" />
            <h1>Understanding the Significance of Migration</h1>
            <ul className="info-list">
                <li><strong>Access to Diverse Educational Opportunities:</strong> Migration opens avenues for individuals to access a variety of educational opportunities, including specialized programs and research facilities.</li>
                <li><strong>Cultural Exchange and Diversity:</strong> Migration contributes to cultural diversity within academic institutions, fostering a globalized learning environment.</li>
                <li><strong>Skill Development and Networking:</strong> Migration for education enhances skills such as adaptability and cross-cultural communication, while also facilitating international networking.</li>
                <li><strong>Brain Drain and Brain Gain:</strong> Migration can lead to “brain drain” as skilled individuals move abroad, but it also contributes to “brain gain” when individuals bring back expertise to their home countries.</li>
            </ul>
        </div>
    );
};

export default MigrationInfo;
