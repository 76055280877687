// src/pages/VerificationPage.js

import React from 'react';
import Header from '../components/Header';
import VerificationInfo from '../components/VerificationInfo';
import Footer from '../components/Footer';

function VerificationPage() {
    return (
        <div className="verification-page">
            <Header />
            <VerificationInfo />
            <Footer />
        </div>
    );
}

export default VerificationPage;
