// src/pages/Provisionalpage.js

import React from 'react';
import Header from '../components/Header';
import ProvisionalInfo from '../components/ProvisionalInfo';  // Corrected import
import Footer from '../components/Footer';

function Provisionalpage() {
    return (
        <div className="provisional-page">
            <Header /> 
            <ProvisionalInfo />
            <Footer />
        </div>
    );
}

export default Provisionalpage;

