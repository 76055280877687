import React from "react";
import "../Styles/Streamline.css";
import markingImage from "../assets/images/Streamline.jpg";

const StreamlineSection = () => {
  return (
    <div className="streamline-section-container">
      <div className="streamline-section-content">
        <h1 className="streamline-section-title">On Screen Marking System</h1>
        <p className="streamline-section-subtitle">By Edulab Educational Exchange Pvt. Ltd.</p>
        <p className="streamline-section-text">
          Streamline your marking process with a user-friendly platform
          designed for efficiency and accuracy. Our system simplifies every
          step, from scanning to reporting. Built by evaluators, for evaluators.
        </p>
        <button className="streamline-section-button">Request Demo</button>
      </div>
      <div className="streamline-section-image">
        <img src={markingImage} alt="On Screen Marking" className="streamline-section-curved-image" />
      </div>
    </div>
  );
};

export default StreamlineSection;
