import React from 'react';
import '../Styles/AttestationInfo.css';
import AttestationImage from '../assets/images/Attestation.jpg'; // adjust the path if needed

const AttestationInfo = () => {
    return (
        <div className="content-section">
            <img className="attestation" src={AttestationImage} alt="Online certification illustration" />
            <h1>Understanding the Significance of Attestation</h1>
            <ul className="info-list">
                <li><strong>Verification of Authenticity:</strong>  Attestation involves the verification of the authenticity of a document or statement. This process is often carried out by a designated authority or individual who confirms that the information presented is accurate and true.</li>
                <li><strong>Legal Validation:</strong> Attestation is crucial for legal validation of documents, especially in formal and official contexts. This ensures that important papers such as contracts, certificates, or affidavits are genuine and can be legally recognized.</li>
                <li><strong>Risk Mitigation:</strong> Attestation serves as a mechanism to mitigate the risk of fraudulent activities. By having documents attested, organizations and individuals can establish a level of trust and confidence in the accuracy of the information being presented.</li>
                <li><strong>Cross-Border Recognition:</strong> In an increasingly globalized world, attestation plays a vital role in cross-border recognition of documents. Many countries require foreign documents to be attested before they are accepted for legal or official purposes, promoting international consistency and reliability.</li>
            </ul>
        </div>
    );
};

export default AttestationInfo;
