// src/pages/Contactpage.js
import React from 'react';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';

const Contactpage = () => {
  return (
    <div>
      <Header />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Contactpage;
