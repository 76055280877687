// InternshipCertificate.js
import React from 'react';
import '../Styles/InternshipCertificate.css';
import CertificateImage from '../assets/images/5adm.jpg';

const InternshipCertificate = () => {
    return (
        <div className="content-section">
            <img className="attestation" src={CertificateImage} alt="Internship Certificate" />
            <h1>Internship Certificate</h1>
            <ul className="info-list">
                <li><strong>Practical Skill Validation:</strong> Internship certificates validate practical skills gained in real-world settings, showcasing the application of theoretical knowledge.</li>
                <li><strong>Professional Development:</strong> These certificates contribute to professional development, acknowledging a student’s commitment and enhancing their competitiveness in the job market.</li>
                <li><strong>Verification of Experience:</strong> Internship certificates provide formal verification of a student’s completion of a specific internship program, crucial for future employers seeking assurance of practical experience.</li>
                <li><strong>Networking and References:</strong> Including internship details, these certificates serve as valuable references, linking students to the professional environments they were part of, aiding networking and job applications.</li>
            </ul>
        </div>
    );
}

export default InternshipCertificate;
