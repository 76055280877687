// src/components/FilterBar.js

import React from 'react';

function FilterBar({ searchQuery, setSearchQuery, category, setCategory, location, setLocation, type, setType }) {
    return (
        <div className="filter-bar">
            <input 
                type="search" 
                placeholder="Search" 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
            />
            <select value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="">All Job Categories</option>
                <option value="IT">IT</option>
                <option value="Finance">Finance</option>
            </select>
            <select value={type} onChange={(e) => setType(e.target.value)}>
                <option value="">All Job Types</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
            </select>
            <select value={location} onChange={(e) => setLocation(e.target.value)}>
                <option value="">All Job Locations</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Pune">Pune</option>
            </select>
        </div>
    );
}

export default FilterBar;
