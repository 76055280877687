import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Aboutpage from './pages/Aboutpage';
import CareerPage from './pages/CareerPage';
import Showpage from './pages/Showpage';
import Attestationpage from './pages/Attestationpage';
import Migrationpage from './pages/Migrationpage';
import Provisionalpage from './pages/Provisionalpage';
import VerificationPage from './pages/VerificationPage';
import InternshipCertificatePage from './pages/InternshipCertificatePage';
import CertificatesPage from './pages/CertificatesPage';
import SecondYearMarksheetPage from './pages/SecondYearMarksheetPage';
import MediumInstructionLetterPage from './pages/MediumInstructionLetterPage';
import Contactpage from './pages/Contactpage';
import WomensDayPage from './pages/WomensDayPage';
import ChristmasPage from './pages/ChristmasPage';  
import IndependenceDayPage from './pages/IndependenceDayPage';
import MensDayPage from './pages/MensDayPage';
import UniversityCelebrationPage from './pages/UniversityCelebrationPage';
import DiwaliPage from './pages/DiwaliPage';
import PicnicPage  from './pages/PicnicPage';
import AuditoriumPage from "./pages/AuditoriumPage";
import StreamlinePage from './pages/StreamlinePage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutpage" element={<Aboutpage />} />
      <Route path="/career" element={<CareerPage />} />
      <Route path="/showcasepage" element={<Showpage />} />
      <Route path="/attestation" element={<Attestationpage />} />
      <Route path="/Attestationpage" element={<Attestationpage />} />
      <Route path="/migrationpage" element={<Migrationpage />} />
      <Route path="/provisionalpage" element={<Provisionalpage />} />
      <Route path="/verificationpage" element={<VerificationPage />} /> 
      <Route path="/internship-certificate" element={<InternshipCertificatePage />} />
      <Route path="/certificates" element={<CertificatesPage />} />
      <Route path="/second-year-marksheet" element={<SecondYearMarksheetPage />} />
      <Route path="/medium-instruction-letter" element={<MediumInstructionLetterPage />} />
      <Route path="/contact" element={<Contactpage />} />
      <Route path="/womens-day" element={<WomensDayPage />} />
      <Route path="/christmas" element={<ChristmasPage />} />
      <Route path="/independence-day" element={<IndependenceDayPage />} />
      <Route path="/mens-day" element={<MensDayPage />} />
      <Route path="/university-celebrations" element={<UniversityCelebrationPage />} />
      <Route path="/diwali" element={<DiwaliPage />} />
      <Route path="/picnic" element={<PicnicPage />} />
      <Route path="/Auditorium" element={<AuditoriumPage />} />
      <Route path="/streamline" element={<StreamlinePage />} />
     


      


    </Routes>
  );
}

export default App;
