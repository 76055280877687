// src/pages/UniversityCelebrationPage.js
import React from 'react';
import Header from '../components/Header';
import UniversityCelebration from '../components/UniversityCelebration';
import Footer from '../components/Footer';

const UniversityCelebrationPage = () => {
  return (
    <>
      <Header />
      <UniversityCelebration />
      <Footer />
    </>
  );
};

export default UniversityCelebrationPage;
