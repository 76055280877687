// src/components/UniversityCelebration.js
import React from 'react';
import '../Styles/UniversityCelebration.css'; // Ensure this path is correct
import mumbaiUniversityImage from '../assets/images/mumbaiuniversity.jpg'; // Ensure this path is correct

const UniversityCelebration = () => {
  return (
    <div className="content">
      <img src={mumbaiUniversityImage} alt="University-Celebration" />
      <h1>University Celebration</h1>
      <p>
        The visit also presented a delightful occasion for celebration, as we shared moments of camaraderie with our colleagues against the backdrop of this esteemed institution.
      </p>
      <p>
        From exploring the iconic architecture to participating in interactive sessions, the day was a blend of learning and team bonding. As we exchanged ideas and experiences in the inspiring surroundings of Mumbai University, the visit became a memorable chapter in our collective professional journey, fostering a deeper sense of connection and appreciation among our team.
      </p>
    </div>
  );
};

export default UniversityCelebration;

