import React from 'react';
import '../Styles/Team.css'; // Ensure Team.css is created and styled

// Importing images directly for better path management
import pratiksir from '../assets/images/pratiksir.png';
import francissir from '../assets/images/francissir.png';
import kumarsir from '../assets/images/kumarsir.png';

const Team = () => {
    const teamMembers = [
        {
            img: pratiksir,
            name: 'Pratik Gandhi, CEO',
            description: 'Pratik Gandhi is the Chief Executive Officer (CEO) of Edulab India.'
        },
        {
            img: francissir,
            name: 'Francis Pinto, CTO',
            description: 'Francis Pinto is the Chief Technology Officer (CTO) of Edulab.'
        },
        {
            img: kumarsir,
            name: 'Kumar Pal, COO',
            description: 'Kumar Pal is the Chief Operating Officer (COO) of Edulab.'
        }
    ];

    return (
        <div className="team-section">
            <h1>Meet Our Team</h1>
            <div className="team-members">
                {teamMembers.map((member, index) => (
                    <div className="team-member" key={index}>
                        <img src={member.img} alt={member.name} />
                        <h2>{member.name}</h2>
                        <p>{member.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;
