// ProvisionalDegreeCertificate.js
import React from 'react';
import '../Styles/ProvisionalDegreeCertificate.css';
import DegreeImage from '../assets/images/6adm.jpg';

const ProvisionalDegreeCertificate = () => {
    return (
        <div className="content-section">
            <img className="attestation" src={DegreeImage} alt="Provisional Degree Certificate" />
            
            <h1>Provisional Degree Certificate</h1>
            <ul className="info-list">
                <li><strong>Temporary Graduation Recognition:</strong> Provisional Degree Certificates provide temporary acknowledgment of graduation for students who have completed their academic requirements but await the official issuance of their degree.</li>
                <li><strong>Immediate Access to Opportunities:</strong> Graduates can use Provisional Degree Certificates to pursue job opportunities, apply for postgraduate programs, or engage in other activities that require proof of completion before the official degree is conferred.</li>
                <li><strong>Conditional Confirmation:</strong> These certificates often come with conditions, such as the successful completion of all academic requirements, ensuring that students meet the necessary criteria for the degree before it becomes official.</li>
                <li><strong>Facilitation of Transition:</strong> Provisional Degree Certificates facilitate the smooth transition of graduates into the professional world or further studies while awaiting the finalization of official documentation.</li>
            </ul>
        </div>
    );
};

export default ProvisionalDegreeCertificate;
