// src/pages/Aboutpage.js

import React from 'react';
import Header from '../components/Header';
import Aboutedu from '../components/Aboutedu';
import WhyChooseUs from '../components/WhyChooseUs';                
import Footer from '../components/Footer';


function Aboutpage() {
    return (
        <div className="about-page">
            <Header /> 
            <Aboutedu />
            <WhyChooseUs/>
            <Footer />
        </div>
    );
}

export default Aboutpage;
