// src/pages/CareerPage.js

import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FilterBar from '../components/FilterBar';
import JobList from '../components/JobList';
import '../Styles/CareerPage.css';



const jobsData = [
    { title: "React Developer", category: "IT", location: "Andheri", type: "Full-time" },
    { title: "WordPress Developer", category: "IT", location: "Andheri", type: "Full-time" },
    { title: "Internship", category: "IT", location: "Mumbai", type: "Part-time" },
    { title: "Full Stack Developer", category: "IT", location: "Mumbai", type: "Full-time" },
    { title: "Backend Developer", category: "IT", location: "Mumbai", type: "Full-time" },
    { title: "React Developer", category: "IT", location: "Mumbai", type: "Full-time" }
];

function CareerPage() {
    const [filteredJobs, setFilteredJobs] = useState(jobsData);
    const [searchQuery, setSearchQuery] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [type, setType] = useState('');

    useEffect(() => {
        const filtered = jobsData.filter(job => {
            return (
                job.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
                (category === '' || job.category === category) &&
                (location === '' || job.location === location) &&
                (type === '' || job.type === type)
            );
        });
        setFilteredJobs(filtered);
    }, [searchQuery, category, location, type]); // Dependencies on the filters and search query

    return (
        <div className="career-page">
            <Header />
            <div className="career-section">
                <h1>Explore Career Opportunities</h1>
                <FilterBar 
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    category={category}
                    setCategory={setCategory}
                    location={location}
                    setLocation={setLocation}
                    type={type}
                    setType={setType}
                />
                <JobList jobs={filteredJobs} />
            </div>
            <Footer />
        </div>
    );
}

export default CareerPage;
