import React from 'react';
import '../Styles/Projects.css'; // Ensure this path is correct
import soloImage from '../assets/images/solo.jpeg';
import mumbaiuniImage from '../assets/images/mumbaiuni.png';
import hsncImage from '../assets/images/Hsnc-university-logo.png';
import spiritImage from '../assets/images/SpiritLogo.jpeg';
import puneImage from '../assets/images/pune34.png';
import sdbiImage from '../assets/images/sdbi.png';
import auroraImage from '../assets/images/aurora-project7.png';
import siliceImage from '../assets/images/silice8.png';
import egeaImage from '../assets/images/EGEA-Project9.png';
import enprendiaImage from '../assets/images/Enprendia10.png';

const Projects = () => {
    return (
        <div className="projects-container">
            <h2 className="projects-heading">See Our Projects</h2>
            <div className="projects-grid">
                <div className="project">
                    <img src={soloImage} alt="Solo Project" />
                </div>
                <div className="project">
                    <img src={mumbaiuniImage} alt="Mumbai University" />
                </div>
                <div className="project">
                    <img src={hsncImage} alt="HSNC University" />
                </div>
                <div className="project">
                    <img src={spiritImage} alt="SPIRIT ERASMUS+" />
                </div>
                <div className="project">
                    <img src={puneImage} alt="Pune University" />
                </div>
                <div className="project">
                    <img src={sdbiImage} alt="SDBI" />
                </div>
                <div className="project">
                    <img src={auroraImage} alt="Aurora Project" />
                </div>
                <div className="project">
                    <img src={siliceImage} alt="SILICE Project" />
                </div>
                <div className="project">
                    <img src={egeaImage} alt="EGEA Project" />
                </div>
                <div className="project">
                    <img src={enprendiaImage} alt="Enprendia Project" />
                </div>
            </div>
        </div>
    );
}

export default Projects;
