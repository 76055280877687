// src/pages/MediumInstructionLetterPage.js
import React from 'react';
import Header from '../components/Header';
import MediumInstructionLetter from '../components/MediumInstructionLetter';
import Footer from '../components/Footer';

const MediumInstructionLetterPage = () => {
    return (
        <div>
            <Header />
            <MediumInstructionLetter />
            <Footer />
            
        </div>
    );
}

export default MediumInstructionLetterPage;
