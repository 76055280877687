// src/pages/Home.js

import React from 'react';
import Header from '../components/Header';
import Slider from '../components/Slider';
import Achievements from '../components/Achievements';
import About from '../components/About';
import Projects  from '../components/Projects';
import Team from '../components/Team';
import PartnersSection from '../components/PartnersSection';
 import Footer from '../components/Footer';

import '../Styles/Home.css';

const Home = () => {
    return (
        <div className="home-container">
            <Header />
            <Slider />
            <Achievements />
            <About />
            <Projects/>
            <Team />
            <PartnersSection /> 
            <Footer />
        </div>
    );
}

export default Home;
