// InternshipCertificatePage.js
import React from 'react';
import Header from '../components/Header';
import InternshipCertificate from '../components/InternshipCertificate';
import Footer from '../components/Footer';

const InternshipCertificatePage = () => {
    return (
        <div>
            <Header />
            <InternshipCertificate />
            <Footer />

        </div>
    );
};

export default InternshipCertificatePage;
