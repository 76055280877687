// src/components/DiwaliCelebration.js
import React from 'react';
import '../Styles/DiwaliCelebration.css'; // Import the associated CSS file
import diwaliImage from '../assets/images/diwali.jpg'; // Ensure the correct image path

const DiwaliCelebration = () => {
  return (
    <div className="content">
      <img
        src={diwaliImage} // Display the imported image
        alt="Diwali-Celebration"
      />
      <h1>Diwali Celebration</h1>
      <p>
        The visit also presented a delightful occasion for celebration, as we shared moments of camaraderie with our colleagues against the backdrop of this esteemed institution.
      </p>
      <p>
        From exploring the iconic architecture to participating in interactive sessions, the day was a blend of learning and team bonding. As we exchanged ideas and experiences in the inspiring surroundings of Mumbai University, the visit became a memorable chapter in our collective professional journey, fostering a deeper sense of connection and appreciation among our team.
      </p>
    </div>
  );
};

export default DiwaliCelebration;
