import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../Styles/Header.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import edulabLogo from '../assets/images/edulablogo2.png';

const Header = () => {
    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    return (
        <header className="header">
            <div className="logo">
                <Link to="/"> {/* Wrapped the logo inside Link */}
                    <img src={edulabLogo} alt="Edulab Logo" />
                </Link>
            </div>

            <nav className={`nav-menu ${menuActive ? 'active' : ''}`}>
                <Link to="/">Home</Link>
                <Link to="/aboutpage">About Us</Link>
                <Link to="/streamline">Osm</Link>
                
                
                <div className="dropdown">
                    <Link to="">ERP Services</Link>
                    <div className="dropdown-content">
                        <Link to="/attestation">Attestation</Link>
                        <Link to="/migrationpage">Migration</Link>
                        <Link to="/provisionalpage">Provisional Eligibility</Link>
                        <Link to="/verificationpage">Verification</Link>
                        <Link to="/internship-certificate">Internship Certificate</Link>
                        <Link to="/certificates">Provisional Degree Certificate</Link>
                        <Link to="/second-year-marksheet">Second Year Marksheet Verification</Link>
                        <Link to="/medium-instruction-letter">Medium Instruction Letter</Link>
                    </div>
                </div>
                <Link to="/contact">Contact</Link>
                <Link to="/showcasepage">Showcase</Link>
                <Link to="/career">Career</Link>
            </nav>

            <div className="buttons">
                {/* <button className="search-btn" aria-label="Search">🔍</button> */}
                <div className="hamburger" onClick={toggleMenu} aria-label="Toggle Menu">
                    <i className="fas fa-bars"></i>
                </div>
                <Link to="/contact" className="btn btn-primary">Get In Touch</Link>
            </div>
        </header>
    );
};

export default Header;
