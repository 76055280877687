import React from 'react';
import Header from '../components/Header';
import Streamline from '../components/Streamline';
import Process from '../components/Process';
import SolutionSection from '../components/SolutionSection'; // Correct import
import KeyFeature from '../components/KeyFeature'; // Importing the new KeyFeature component
import EducationBenefits from '../components/EducationBenefits';
import Client from "../components/Client";
import Support from "../components/Support"; 
import Screenshot from "../components/Screenshot";
import Footer from '../components/Footer';

function StreamlinePage() {
  return (
    <div className="streamline-page">
      <Header />
      <Streamline />
      <Process />
      <SolutionSection /> {/* Corrected component name */}
      <KeyFeature /> {/* Added KeyFeature component */}
      <EducationBenefits />
      <Client />
      <Support />
      <Screenshot />
      <Footer />
    </div>
  );
}

export default StreamlinePage;
