import React from 'react';
import '../Styles/EducationBenefits.css'; // Importing CSS file for styling

const EducationBenefits = () => {
  return (
    <section className="edu-benefits-section">
      <h2 className="edu-benefits-title">Benefits for Education Bodies</h2>
      <div className="edu-benefits-grid">
        <div className="edu-benefit-card">
          <h3 className="edu-benefit-heading">Efficiency & Time Savings</h3>
          <p className="edu-benefit-text">
            Reduce marking time and workload, allowing teachers to focus on other important tasks 
            like lesson planning and student interaction.
          </p>
        </div>
        <div className="edu-benefit-card">
          <h3 className="edu-benefit-heading">Improved Student Feedback</h3>
          <p className="edu-benefit-text">
            Provide more personalized and timely feedback, enhancing student learning and engagement.
          </p>
        </div>
        <div className="edu-benefit-card">
          <h3 className="edu-benefit-heading">Data-Driven Insights</h3>
          <p className="edu-benefit-text">
            Gain valuable data to understand student performance, identify areas for improvement, 
            and inform curriculum decisions.
          </p>
        </div>
      </div>
    </section>
  );
};

export default EducationBenefits;
