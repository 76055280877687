// src/pages/ChristmasPage.js
import React from 'react';
import '../Styles/ChristmasPage.css';
import Header from '../components/Header'; // Assuming you have a Header component
import ChristmasCelebration from '../components/ChristmasCelebration'; // Import the ChristmasCelebration component
import Footer from '../components/Footer'; // Assuming you have a Footer component

const ChristmasPage = () => {
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Christmas Celebration Content */}
      <ChristmasCelebration />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ChristmasPage;
