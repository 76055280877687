// src/pages/PicnicPage.js
import React from 'react';
import OfficePicnic from '../components/OfficePicnic'; // Import the OfficePicnic component
import Header from '../components/Header'; // Import the Header component
import Footer from '../components/Footer'; // Import the Footer component

const PicnicPage = () => {
  return (
    <div>
      <Header /> {/* Add the Header */}
      <OfficePicnic /> {/* Add the Office Picnic Content */}
      <Footer /> {/* Add the Footer */}
    </div>
  );
};

export default PicnicPage;
