// src/pages/Attestationpage.js

import React from 'react';
import Header from '../components/Header';
import AttestationInfo from '../components/AttestationInfo';               
import Footer from '../components/Footer';


function Attestationpage() {
    return (
        <div className="about-page">
            <Header /> 
            <AttestationInfo />
            <Footer />
        </div>
    );
}

export default Attestationpage;
