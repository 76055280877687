import React from 'react';
import '../Styles/ProvisionalInfo.css'; // Ensure this path matches your project structure
import EligibilityImage from '../assets/images/3adm.jpg'; // Update path as necessary

const ProvisionalInfo = () => {
    return (
        <div className="content-section">
            <img className="attestation" src={EligibilityImage} alt="Provisional Eligibility Illustration" />
            
            <h1>Understanding Provisional Eligibility in Admissions</h1>
            
            <ul className="info-list">
                <li>
                    <strong>Temporary Admission Status:</strong> 
                    Provisional eligibility allows students temporary admission, even if they haven’t met all standard requirements.
                </li>
                <li>
                    <strong>Conditional Acceptance:</strong> 
                    Students may be provisionally eligible with conditions, such as achieving specific grades or submitting required documents within a set timeframe.
                </li>
                <li>
                    <strong>Probationary Period:</strong> 
                    Provisional eligibility often involves a monitored period, after which students may transition to full eligibility based on performance.
                </li>
                <li>
                    <strong>Flexibility for Special Cases:</strong> 
                    Provisional eligibility accommodates special cases, acknowledging unique circumstances like transfers or individual challenges.
                </li>
            </ul>
        </div>
    );
}

export default ProvisionalInfo;
