import React from 'react';
import Header from '../components/Header'; // Ensure Header.js is in src/components
import IndependenceDayCelebration from '../components/IndependenceDayCelebration';
import Footer from '../components/Footer'; // Ensure Footer.js is in src/components

const IndependenceDayPage = () => {
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Independence Day Celebration Content */}
      <IndependenceDayCelebration />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default IndependenceDayPage;
