import React, { useState } from "react";
import "../Styles/Process.css"; // Import the CSS file

const Process = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="process-container">
      <h2>How It Works</h2>
      <div className={`process-card ${isExpanded ? "expanded" : ""}`}>
        <div className="process-header" onClick={() => setIsExpanded(!isExpanded)}>
          <h3>OSM Process</h3>
          <span className="toggle-icon">{isExpanded ? "▲" : "▼"}</span>
        </div>
        {isExpanded && (
          <div className="process-content">
            <div className="step">
              <span className="step-number">1</span>
              <div>
                <h4>Setting up the Scanning Environment</h4>
                <p>Set up a secure and efficient scanning environment with OSM’s easy-to-use tools.</p>
              </div>
            </div>
            <div className="step">
              <span className="step-number">2</span>
              <div>
                <h4>Start of Scanning</h4>
                <p>Begin scanning with OSM’s intuitive interface, ensuring secure and accurate digitization of exam papers.</p>
              </div>
            </div>
            <div className="step">
              <span className="step-number">3</span>
              <div>
                <h4>Assigning Papers to Evaluators</h4>
                <p>Efficiently assign scanned papers to evaluators with OSM’s streamlined distribution system.</p>
              </div>
            </div>
            {/* Adding Step 4 */}
            <div className="step">
              <span className="step-number">4</span>
              <div>
                <h4>Reporting of Checked Papers</h4>
                <p>Generate detailed reports of checked papers effortlessly with OSM’s automated reporting features.</p>
              </div>
            </div>
            {/* Adding Step 5 */}
            <div className="step">
              <span className="step-number">5</span>
              <div>
                <h4>Marksheet Generation</h4>
                <p>Instantly create accurate marksheets with OSM’s automated generation system, streamlining the grading process.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Process;
