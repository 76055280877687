// src/components/Achievements.js
import React from 'react';
import '../Styles/Achievements.css';
import collaborationImage from '../assets/images/Collaboration-pana1.png';
import addUserImage from '../assets/images/Add-User-amico2.png';
import developerActivityImage from '../assets/images/Developer-activity-amico3.png';
import highFiveImage from '../assets/images/High-five-pana4.png';

function Achievements() {
    return (
        <section className="achievements-section">
            <h1>EDULAB INDIA</h1>
            <div className="achievements-cards">
                <div className="achievement-card">
                    <img src={collaborationImage} alt="What we Achieved" />
                    <h2>What we Achieved</h2>
                    <p>Catered to more than <strong>25,000</strong> students till date for various programs.</p>
                </div>
                <div className="achievement-card">
                    <img src={addUserImage} alt="Members Of" />
                    <h2>Members Of</h2>
                    <p>Proud member of <strong>WYSTC</strong> (UNESCO), <strong>NAFSA</strong>, and <strong>EIL</strong>.</p>
                </div>
                <div className="achievement-card">
                    <img src={developerActivityImage} alt="Programs Offered" />
                    <h2>Programs Offered</h2>
                    <p>Programs offered in over <strong>28 countries</strong>.</p>
                </div>
                <div className="achievement-card">
                    <img src={highFiveImage} alt="Biggest in India" />
                    <h2>Biggest In India</h2>
                    <p>Edulab is one of the biggest capacity-building organizations in India.</p>
                </div>
            </div>
        </section>
    );
}

export default Achievements;
