// MediumInstructionLetter.js
import React from 'react';
import '../Styles/MediumInstructionLetter.css';
import MediumInstructionImage from '../assets/images/8adm.jpg';

const MediumInstructionLetter = () => {
    return (
        <div className="content-section">
            <img className="attestation" src={MediumInstructionImage} alt="Medium-of-Instruction-Letter" />
            <h1>Medium of Instruction Letter</h1>
            <ul>
                <li><strong>Language Clarification:</strong> A Medium of Instruction Letter provides clarity on the language used for teaching and assessment in a particular academic program, helping students and institutions understand the linguistic context.</li>
                <li><strong>Admission Requirement Fulfillment:</strong> For international students, a Medium of Instruction Letter may fulfill admission requirements, ensuring that they meet language proficiency standards set by the institution.</li>
                <li><strong>Official Documentation:</strong> It serves as an official document from the educational institution, confirming the language of instruction and facilitating communication between students, institutions, and other stakeholders.</li>
                <li><strong>Global Recognition:</strong> The letter contributes to global recognition of academic qualifications by transparently stating the language in which a student has pursued their studies, aiding in the evaluation of educational credentials across borders.</li>
            </ul>
        </div>
    );
}

export default MediumInstructionLetter;
