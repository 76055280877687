// src/pages/MensDayPage.js
import React from 'react';
import Header from '../components/Header';
import MensDayCelebration from '../components/MensDayCelebration';
import Footer from '../components/Footer';

const MensDayPage = () => {
  return (
    <div>
      <Header />
      <main>
        <MensDayCelebration />
      </main>
      <Footer />
    </div>
  );
};

export default MensDayPage;
