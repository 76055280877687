// src/pages/Showpage.js

import React from 'react';
import Header from '../components/Header';
import Showcase from '../components/Showcase';               
import Footer from '../components/Footer';


function Showpage() {
    return (
        <div className="show-page">
            <Header /> 
            <Showcase />
            <Footer />
        </div>
    );
}

export default Showpage;
