import React from 'react';
import '../Styles/SecondYearMarksheetVerification.css';
import verificationImage from '../assets/images/7adm.jpg';

const SecondYearMarksheetVerification = () => {
    return (
        <div className="content-section">
            <img className="attestation" src={verificationImage} alt="Second Year Marksheet Verification" />
            
            <h1>Second Year Marksheet Verification</h1>
            <ul className="info-list">
                <li>
                    <strong>Academic Progress Validation:</strong> 
                    Second Year Marksheet Verifications confirm the academic performance of students for their second year of study, providing validation of the grades and courses completed.
                </li>
                <li>
                    <strong>Proof of Eligibility for Further Studies:</strong> 
                    This verification serves as proof of students' eligibility to continue their studies or apply for internships, training programs, or other opportunities that require confirmation of their academic standing.
                </li>
                <li>
                    <strong>Prevention of Fraudulent Claims:</strong> 
                    By verifying the authenticity of second-year marksheets, institutions and employers can prevent fraudulent claims of academic progress or inflated grades.
                </li>
                <li>
                    <strong>Required for Transfer or Study Abroad Programs:</strong> 
                    For students planning to transfer to other institutions or participate in study abroad programs, Second Year Marksheet Verification is often a prerequisite to ensure the accuracy of their academic records.
                </li>
            </ul>
        </div>
    );
};

export default SecondYearMarksheetVerification;
