import React from 'react';
import '../Styles/PartnersSection.css'; // Correct path to your CSS
import soloImage from '../assets/images/solo.jpeg'; // Correct path to images
import mumbaiuniImage from '../assets/images/mumbai uni-text-83.png';
import hsncImage from '../assets/images/Hsnc-university-logo.png';
import spiritImage from '../assets/images/SpiritLogo.jpeg';
import puneImage from '../assets/images/pune34.png';
import sdbiImage from '../assets/images/sdbi.png';

const PartnersSection = () => {
    return (
        <div className="partners-section">
            <h2>Our Partner Colleges</h2>
            <div className="logos">
                <div className="logo-item">
                    <img src={soloImage} alt="Logo 1" />
                </div>
                <div className="logo-item">
                    <img src={hsncImage} alt="Logo 2" />
                </div>
                <div className="logo-item">
                    <img src={sdbiImage} alt="Logo 3" />
                </div>
                <div className="logo-item">
                    <img src={mumbaiuniImage} alt="Logo 4" />
                </div>
                <div className="logo-item">
                    <img src={spiritImage} alt="Logo 5" />
                </div>
                <div className="logo-item">
                    <img src={puneImage} alt="Logo 6" />
                </div>
            </div>
        </div>
    );
}

export default PartnersSection;
