// src/components/MensDayCelebration.js
import React from 'react';
import '../Styles/MensDayCelebration.css'; // Make sure this CSS file is created
import mensDayImage from '../assets/images/mensday.jpg'; // Adjust the path to your image location

const MensDayCelebration = () => {
  return (
    <div className="content">
      <img src={mensDayImage} alt="Men's Day Celebration" />
      <h1>Men's Positive Contribution in the Workplace</h1>
      <p>Celebrating Men's Day in the office was a thoughtful recognition of the contributions and diverse strengths that men bring to the workplace.</p>
      <p>
        The atmosphere resonated with appreciation as colleagues acknowledged the achievements, leadership, and positive impact of male colleagues across various roles. The day began with expressions of gratitude and camaraderie, emphasizing the importance of fostering a supportive work environment for everyone.
      </p>
    </div>
  );
};

export default MensDayCelebration;
