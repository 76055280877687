// src/pages/WomansDayPage.js
import React from 'react';
import Header from '../components/Header';
import WomensDay from '../components/WomensDay';
import Footer from '../components/Footer';


function WomansDayPage() {
    return (
        <div className="WomansDay-page">
            <Header />
            <WomensDay />
            <Footer />
        </div>
    );
}

export default WomansDayPage;
