// src/components/ChristmasCelebration.js
import React from 'react';
import '../Styles/ChristmasCelebration.css';


import christmasImage from '../assets/images/cristmas.jpg'; // Ensure correct image path

const ChristmasCelebration = () => {
  return (
    <div>
      <div className="content">
        <img
          src={christmasImage} // Display the imported image
          alt="Christmas-Celebration"
        />
        <h1>Celebrated Christmas</h1>
        <p>
          Celebrating Christmas in the office was a joyous and festive affair
          that brought a delightful sense of camaraderie among colleagues.
        </p>
        <p>
          The entire office space was adorned with twinkling lights, tinsel, and
          colorful decorations that instantly infused a cheerful holiday spirit.
          The festive ambiance was further enhanced by the sound of laughter and
          merry conversations echoing through the halls as colleagues exchanged
          warm wishes and shared delightful moments.
        </p>
      </div>
    </div>
  );
};

export default ChristmasCelebration;
