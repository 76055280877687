import React, { useState } from "react";

function JobList({ jobs }) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const openPopup = (job) => {
    setSelectedJob(job);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedJob(null);
  };

  return (
    <div className="job-listings">
      {jobs.map((job, index) => (
        <div key={index} className="job-card">
          <h3>{job.title}</h3>
          <p>{job.category}</p>
          <p>{job.location}</p>
          <button className="more-details-btn" onClick={() => openPopup(job)}>
            More Details &rarr;
          </button>
        </div>
      ))}

      {showPopup && selectedJob && (
        <div className="popup-overlay">
          <div className="popup-box">
            <span className="popup-close" onClick={closePopup}>
              &times;
            </span>
            <div className="popup-body">
              <p>You can send your CV to:</p>
              <a href="mailto:humanresources@edulab.in" className="email-link">
                humanresources@edulab.in
              </a>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          /* Button Styling */
          .more-details-btn {
            color: #007bff;
            border: none;
            background: none;
            font-size: 16px;
            cursor: pointer;
            text-decoration: none;
            transition: color 0.3s;
          }

          .more-details-btn:hover {
            color: #0056b3;
            text-decoration: underline;
          }

          /* Popup Overlay */
          .popup-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          /* Popup Box */
          .popup-box {
            background: white;
            width: 380px;
            padding: 25px;
            border-radius: 12px;
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
            text-align: center;
            position: relative;
            animation: fadeIn 0.3s ease-in-out;
          }

          /* Close Button */
          .popup-close {
            position: absolute;
            top: 12px;
            right: 15px;
            font-size: 22px;
            cursor: pointer;
            color: #777;
            transition: color 0.3s;
          }

          .popup-close:hover {
            color: #333;
          }

          /* Popup Body */
          .popup-body {
            font-size: 16px;
            color: #333;
            padding-top: 10px;
          }

          /* Email Link */
          .email-link {
            display: inline-block;
            margin-top: 10px;
            color: #007bff;
            font-weight: bold;
            text-decoration: none;
            font-size: 18px;
            transition: color 0.3s;
          }

          .email-link:hover {
            color: #0056b3;
            text-decoration: underline;
          }

          /* Fade-in animation */
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: scale(0.9);
            }
            to {
              opacity: 1;
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
}

export default JobList;
