// src/pages/Migrationpage.js

import React from 'react';
import Header from '../components/Header';
import AttestationInfo from '../components/MigrationInfo';               
import Footer from '../components/Footer';


function Migrationpage() {
    return (
        <div className="migration-page">
            <Header /> 
            <AttestationInfo />
            <Footer />
        </div>
    );
}

export default Migrationpage;
