import React from "react";
import "../Styles/KeyFeature.css";
import featureImage from "../assets/images/keyfeature.jpg"; // Update with your actual image path

const KeyFeature = () => {
  const features = [
    {
      id: 1,
      title: "Integration to existing exam management/marksheet generation",
      description:
        "Integrate OSM with your exam management and marksheet systems for seamless, automated data transfer and accurate reporting.",
    },
    {
      id: 2,
      title: "Best in class user experience",
      description:
        "Navigate effortlessly with our user-friendly design, optimized for handling large volumes of exam papers.",
    },
    {
      id: 3,
      title: "Comprehensive report generation",
      description:
        "Generate comprehensive, detailed reports instantly with OSM's automated reporting system.",
    },
    {
      id: 4,
      title: "Experience safe and secure exam management",
      description:
        "OSM’s comprehensive security protocols ensure data protection and confidentiality throughout the entire process.",
    },
  ];

  return (
    <div className="key-feature-container">
      <div className="feature-image">
        <img src={featureImage} alt="Feature" />
      </div>
      <div className="feature-content">
        <h2 className="feature-title">Key Features</h2>
        <div className="feature-list">
          {features.map((feature) => (
            <div key={feature.id} className="feature-item">
              <div className="feature-number">{feature.id}</div>
              <div className="feature-text">
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyFeature;
