import React from "react";
import "../Styles/Client.css"; // Import the CSS file

// Import images correctly if they are inside "src/assets/images/"
import solapur from "../assets/images/acedamylogo.png";
import hsnc from "../assets/images/solapurv.jpg";
import solapurUni from "../assets/images/Hsnc-university-logo.png";
import sdbi from "../assets/images/sdbi.png";

const clients = [
  { name: "MIT", image: solapur },
  { name: "HSNC University", image: hsnc },
  { name: "Solapur University", image: solapurUni },
  { name: "SDBI", image: sdbi }
];

const Client = () => {
  return (
    <div className="client-section">
      <h2 className="client-title">Our Clients</h2>
      <div className="client-logos">
        {clients.map((client, index) => (
          <div key={index} className="client-logo">
            <img src={client.image} alt={client.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Client;
