import React from 'react';
import '../Styles/WomensDay.css'; // Ensure this path matches your CSS file location
import womansdayImage from '../assets/images/womansday.jpg';

const WomensDay = () => {
  return (
    <div className="content">
      

      <img src={womansdayImage} alt="Women's Day Celebration" className="content-image" />
      <h1>Celebrated Women's Day</h1>
      <p>
        Celebrating Women's Day in the office was a heartfelt tribute to the incredible contributions and achievements of the women in our workplace.
      </p>
      <p>
        The office ambiance was adorned with vibrant colors, empowering quotes, and flowers, creating an atmosphere of appreciation and recognition. Colleagues came together to acknowledge the strengths, resilience, and talents of the women who play pivotal roles across various departments.
      </p>
    </div>
  );
};

export default WomensDay;
