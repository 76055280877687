import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import '../Styles/Showcase.css';

const Showcase = () => {
  const events = [
    { title: "Women's Day", description: "Celebrate the achievements and contributions of women in our lives.", link: "/womens-day", className: "womens-day-box" },
    { title: "Christmas", description: "Join us for a festive celebration of joy and togetherness.", link: "/christmas", className: "christmas-box" },
    { title: "Independence Day", description: "Commemorate our nation's freedom and spirit.", link: "/independence-day", className: "independence-day-box" },
    { title: "Men's Day", description: "Honor and appreciate the men in our lives.", link: "/mens-day", className: "mens-day-box" },
    { title: "Mumbai University", description: "Explore the vibrant campus and its offerings.", link: "/university-celebrations", className: "mumbai-university-box" },
    { title: "Diwali", description: "Celebrate the festival of lights with us.", link: "/diwali", className: "new-section-2-box" },
    { title: "Travel", description: "Description for the new event 1.", link: "/picnic", className: "new-section-3-box" },
    { title: "Auditorium Meeting", description: "Description for the new event 2.", link: "/auditorium", className: "new-section-4-box" },
    { title: "Special Event", description: "Description for the new event 3.", link: "/specialevent11", className: "new-section-5-box" }
  ];

  return (
    <div className="grid-container">
      {events.map((event, index) => (
        <Link to={event.link} className={`holiday-box ${event.className}`} key={index}>
          <div className="box-content">
            <h2 className="box-title">{event.title}</h2>
            <p className="box-description">{event.description}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Showcase;
