import React from 'react';
import '../Styles/ContactUs.css';

const ContactUs = () => {
  return (
    <div className="unique-contact-us">
      {/* Title Section */}
      <div className="unique-contact-header">
        <h1>Get in Touch</h1>
        <p>We'd love to hear from you! Reach out to us anytime.</p>
      </div>

      {/* Contact Info Section */}
      <div className="unique-contact-info">
        <div className="unique-contact-card">
          <i className="fas fa-phone"></i>
          <h3>Phone</h3>
          <p><a href="tel:+912269432222">022-6943-2222</a></p>
        </div>
        <div className="unique-contact-card-email">
          <i className="fas fa-envelope"></i>
          <h3>Email</h3>
          <p><a href="mailto:info@edulab.in">info@edulab.in</a></p>
        </div>
        <div className="unique-contact-card-address">
          <i className="fas fa-map-marker-alt"></i>
          <h3>Address</h3>
          <p>13, Kondivita Rd, near Pidilite Industries, Andheri East, Mumbai, Maharashtra 400093</p>
        </div>
      </div>

      {/* Contact Form & Map Section */}
      <div className="unique-contact-container">
        <div className="unique-contact-form">
          <h2>Send us a Message</h2>
          <form>
            <input className="unique-contact-input" type="text" placeholder="Your Name" required />
            <input className="unique-contact-email" type="email" placeholder="Your Email" required />
            <textarea className="unique-contact-textarea" placeholder="Your Message" required></textarea>
            <button className="unique-contact-button" type="submit">Send Message</button>
          </form>
        </div>

        {/* Map Section */}
        <div className="unique-contact-map">
          <h2>Our Location</h2>
          <iframe
            title="Edulab Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7539.625255516833!2d72.86029816977536!3d19.115874199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9225be79ec5%3A0x72f0d6fac210e7a1!2sEdulab%20educational%20Exchange%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1729774630512!5m2!1sen!2sin"
            width="100%"
            height="300"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
